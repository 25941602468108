import { sortBy } from 'lodash-es'

export const getConferenceLink = (confId) => {
  return '/' + confId.replace('-', '/')
}

export const getCommunityLink = (confId) => {
  return '/community/' + confId
}

export const formatMins = (minutes, absolute) => {
  let m = Math.abs(minutes % 60)
  let h = (minutes - m) / 60
  if (absolute) h = Math.abs(h)
  return h.toString() + ':' + (m < 10 ? '0' : '') + m.toString()
}

export const setColorTheme = (string, color) => {
  if (color === 'europe') color = 'emea'
  const conferences = ['apac', 'emea', 'community', 'usa', 'uxdx']
  const stringsToExclude = conferences.filter((conf) => {
    return conf !== color
  })
  const regex = stringsToExclude.map((conf) => {
    return new RegExp('[a-zA-Z-:]*' + conf + '[-0-9]*', 'g')
  })
  let reducedString = string
  regex.forEach((regex) => {
    reducedString = reducedString.replace(regex, '')
  })
  return reducedString
}

export const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null

      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const getTalkTitle = (session, conference) => {
  const speakerNames = session.data.Speakers?.filter(
    (speaker) => speaker?.data?.Person?.[0]?.data?.Person_Status === 'Published',
  )
    .map((position) => {
      const speaker = position.data.Person[0]
      let name =
        speaker.data.Person_First + ' ' + speaker.data.Person_Last + ', ' + position.data.Person_Job_Title
      if (position.data.Company && position.data.Company[0] && position.data.Company[0].data.Company_Name)
        name += ', ' + position.data.Company[0].data.Company_Name
      return name
    })
    .join(', ')
  let title = session.data.Talk_Title
  title += speakerNames ? ' by ' + speakerNames : ''
  title += ' | ' + conference?.data?.Conference_Name

  return title
}

export const getSpeakerNames = (speakers) => {
  if (!speakers) return ''
  return speakers
    .map((speaker) => {
      const companyName =
        speaker.data.Company && speaker.data.Company[0] && speaker.data.Company[0].data.Company_Name
      const details =
        speaker.data.Person[0].data.Person_First +
        ' ' +
        speaker.data.Person[0].data.Person_Last +
        ', ' +
        speaker.data.Person_Job_Title
      const returnString = companyName ? details + ', ' + companyName : details
      return returnString
    })
    .join(' · ')
}

export const getCurrencySymbol = (locale, currency) => {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export const getConferenceOver = (conferenceEndDate) => {
  const dayDelay = 1000 * 60 * 60 * 24
  return Date.now() > new Date(conferenceEndDate).getTime() + dayDelay
}

export const getConferenceLocation = (conference) => {
  if (!conference) return null
  if (!conference.data.Type) throw Error('Missing Type', conference)
  if (conference.data.Type === 'Conference') {
    if (!conference.data.Location) throw Error('Missing Location', conference)
    return conference.data.Location.toLowerCase()
  } else return 'community'
}

export const getEventLink = (conferenceId, conferenceLocation, link) => {
  if (!conferenceId) throw Error('Missing Id in getEventLink')
  if (!conferenceLocation) throw Error('Missing conferenceLocation in getEventLink')
  // if the first character of the link is a '/' remove it
  if (link && link.charAt(0) === '/') link = link.substr(1)

  let extra = link
    ? conferenceLocation !== 'community' || link.substr(0, 4) === 'live'
      ? '/' + link
      : '#' + link
    : ''
  // if extra doesn't end with a slash then add it
  // if (!extra || (extra && extra.slice(-1) !== '/' && extra.indexOf('#') === -1))
  //   extra += '/'
  // console.log(conferenceId, conferenceLocation, extra)
  if (conferenceLocation && conferenceLocation === 'community') {
    // console.log(getCommunityLink(conferenceId) + extra)
    return getCommunityLink(conferenceId) + extra
  }
  return getConferenceLink(conferenceId) + extra
}

export const getTrainingLink = (productId) => {
  return '/training/' + productId
}

export const getEventLinkByType = (productId, type, link) => {
  let extra = ''
  if (link) extra = '/' + link
  if (type === 'Conference') return getConferenceLink(productId) + extra
  if (type === 'Community') return getCommunityLink(productId) + extra
  if (type === 'Training') return getTrainingLink(productId) + extra
  return '/'
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const calcS = (size) => {
  const Totalwidth = 595.28
  const Totalheight = 841.89
  const defaultsizeW = 2480
  return (size * Totalwidth) / defaultsizeW + 'px'
}

export const isOdd = (index) => {
  return index % 2 === 1
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

export const colours = {
  Product: '#DC2626',
  UX: '#EA580C',
  Design: '#CA8A04',
  Development: '#65A30D',
  Project: '#059669',
  Marketing: '#0891B2',
  'CEO / Founder': '#0D9488',
  "C'Suite": '#0284C7',
  'Senior Management': '#7C3AED',
  'Experienced Practitioners': '#C026D3',
  'Junior / Entry Level': '#DB2777',
  Enterprise: '#1E40AF',
  Large: '#1D4ED8',
  Medium: '#2563EB',
  Small: '#5B21B6',
  Startup: '#6D28D9',
  'Self Employed / Freelance': '#7C3AED',
}

export const getConferenceVenueType = (venues, venueType, withName) => {
  const seenCities = new Set() // Set to track unique cities
  return sortBy(venues, 'data.Venue_Type').reduce((initialValue, venue, index) => {
    const venueCity = venue.data.Venue_City
    const venueName =
      venue.data.Venue_Type === 'Online'
        ? 'Online'
        : withName
          ? venueCity + ' (' + venue.data.Venue_Name + ')'
          : venueCity

    // Skip if the city is already in the Set
    if (seenCities.has(venueCity)) {
      return initialValue
    }

    // Add the city to the Set
    seenCities.add(venueCity)

    const separator = index < venues.length - 1 ? ', ' : ' and '
    if (initialValue) return initialValue + separator + venueName
    return venueName
  }, '')
}

export const getConferenceVenueNamePostCode = (venues) => {
  const inPersonVenues = venues.filter((venue) => venue.data.Venue_Type !== 'Online')
  if (inPersonVenues.length === 0) return ''
  else if (inPersonVenues.length === 1) {
    const venue = inPersonVenues[0]
    return venue.data.Venue_Name
    // return venue.data.Venue_Name + ', ' + venue.data.Post_Code
  } else return ''
}

export const getNumberDays = (startDate, endDate) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const diffTime = Math.abs(end - start)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  const diffDaysNumber = diffDays + 1
  const dayText = diffDaysNumber > 1 ? 'days' : 'day'
  return diffDaysNumber + ' ' + dayText
}

export const dynamicClassNames = (string, color) => {
  if (color === 'europe') color = 'emea'

  let newString = string.replace(/uxdx/g, color)
  return newString
}

export const getQueryParam = (searchPath, param) => {
  // get query parameters from url
  const query = new URLSearchParams(searchPath)
  return query.get(param) || null
}
