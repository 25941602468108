import { trim } from 'lodash-es'
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import {
  getDatabase,
  get,
  update,
  onValue,
  ref,
  set,
  orderByChild,
  endAt,
  query,
  startAt,
  runTransaction,
  remove,
  push,
} from 'firebase/database'
import {
  getAuth,
  getIdToken,
  getIdTokenResult,
  updateEmail,
  EmailAuthProvider,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
} from 'firebase/auth'
import { FirebaseError } from '@firebase/util'
import {
  StripeInputDetails,
  StripeInvoiceDetails,
  StripeInvoiceItem,
  MailOptions,
  AssignTicketOptions,
  AddTicketQuestionsFunction,
  GetTicketQuestionsFunction,
  FindTicketResponse,
  FindTicketApiResponse,
  TicketQuestion,
  StripeCustomer,
} from './api-gateway.interfaces'
import { FirebaseApp } from '@firebase/app'
import { DatabaseReference } from '@firebase/database'
import {
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
  runTransaction as firestoreRunTransaction,
  collection,
  where,
  orderBy,
  addDoc,
  getDocs,
  getDoc,
  serverTimestamp,
  query as firestoreQuery,
} from 'firebase/firestore'
import { CheckInList } from 'src/components/admin/conferenceAdminCheckInLists'
import { UserPermission } from 'src/hooks/useCheckInListsPermissionObserver'
import { groupBy } from 'lodash-es'
import * as Sentry from '@sentry/gatsby'

interface ticketDetails {
  ticketId: string
  firstName: string
  lastName: string
  jobTitle: string
  company: string
  history: any
}

class ApiGateway {
  constructor() {}

  static getHostname = () => {
    return `https://us-central1-${ApiGateway.getFirebaseProjectId()}.cloudfunctions.net/`
  }

  static getFirebaseProjectId = () => {
    if (!process.env.GATSBY_FIREBASE_PROJECT_ID) {
      console.error('GATSBY_FIREBASE_PROJECT_ID not provided')
    }
    return process.env.GATSBY_FIREBASE_PROJECT_ID
  }

  static registerFreeTicket = async (requestBody) => {
    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-registerFreeTicket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
      if (response.ok) {
        return await response.json()
      } else {
        return { error: response.statusText }
      }
    } catch (e) {
      return { error: e, message: 'Error registering free ticket' }
    }
  }

  static registerPaidTicket = async (requestBody) => {
    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-registerPaidTicket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
      if (response.ok) {
        return await response.json()
      } else {
        return { error: response.statusText }
      }
    } catch (e) {
      return { error: e, message: 'Error registering paid ticket' }
    }
  }

  static getStripeCustomer = async (customerId: string, env: string) => {
    try {
      const stripeResponse = await fetch(
        'https://us-central1-uxdx-48a34.cloudfunctions.net/stripe-getStripeCustomer',
        {
          method: 'POST',
          body: JSON.stringify({ customerId, env }),
        },
      )
      if (stripeResponse.ok) {
        return await stripeResponse.json()
      } else {
        return { error: stripeResponse.statusText }
      }
    } catch (e) {
      return { error: e }
    }
  }

  static handleStripePayment = async (sessionDetails: StripeInputDetails, env: string) => {
    try {
      const stripeResponse = await fetch(
        'https://us-central1-uxdx-48a34.cloudfunctions.net/stripe-createCheckoutSession',
        {
          method: 'POST',
          body: JSON.stringify({ sessionDetails, env }),
        },
      )
      if (stripeResponse.ok) {
        return await stripeResponse.json()
      } else {
        return { error: stripeResponse.statusText }
      }
    } catch (e) {
      return { error: e }
    }
  }

  static requestStripeInvoice = async (
    invoiceDetails: StripeInvoiceDetails,
    lineItems: StripeInvoiceItem[],
    customer: StripeCustomer,
    updatedCustomerInfo: boolean,
    updatedCustomerTaxIds: boolean,
    env: string,
  ) => {
    try {
      const stripeResponse = await fetch(
        'https://us-central1-uxdx-48a34.cloudfunctions.net/stripe-requestStripeInvoice',
        {
          method: 'POST',
          body: JSON.stringify({
            invoiceDetails,
            lineItems,
            customer,
            updatedCustomerInfo,
            updatedCustomerTaxIds,
            env,
          }),
        },
      )
      const response = await stripeResponse.json()
      return response
    } catch (e) {
      console.log('error')
      return { error: e }
    }
  }

  static observeOrder = (
    firebaseApp: FirebaseApp,
    orderId: string,
    handleUserOrders: (...props: any) => void,
  ): DatabaseReference => {
    const userOrderDBRef = ref(getDatabase(firebaseApp), `/orders/all/${orderId}`)

    onValue(
      userOrderDBRef,
      (snapshot) => {
        const data = snapshot.val()

        handleUserOrders(data)
      },
      (error) => {
        console.error(error)
      },
    )

    return userOrderDBRef
  }

  static getOrder = async (firebaseApp: FirebaseApp, orderId: string) => {
    const orderSnap = await get(ref(getDatabase(firebaseApp), `/orders/all/${orderId}`))
    if (!orderSnap.exists()) {
      return []
    }

    const order = orderSnap.val()

    return order
  }

  static updateOrderFeedback = async (
    firebaseApp: FirebaseApp,
    orderId: string,
    feedback: { whyOrder: string; whyOrderToday: string },
  ) => {
    await update(ref(getDatabase(firebaseApp), `/orders/all/${orderId}`), { feedback })
  }

  static setOrderCompleted = async (orderId: string) => {
    try {
      const response = await fetch(`${ApiGateway.getHostname()}orderManagement-markOrderCompleted`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId: orderId }),
      })
      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (e) {
      return { error: e, message: 'Error registering paid ticket' }
    }
  }

  static manuallySyncTickets = async (email: string) => {
    try {
      const response = await fetch(
        `${ApiGateway.getHostname()}getTicket-manualSyncTicketsFromFirestoreToFirebase`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        },
      )
      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (e) {
      return { error: e, message: 'Error syncing ticket' }
    }
  }

  static observeUserOrders = (
    firebaseApp: FirebaseApp,
    uid: string,
    handleUserOrders: (...props: any) => void,
  ): DatabaseReference => {
    const userOrdersDBRef = ref(getDatabase(firebaseApp), `/orders/userOrders/${uid}`)

    onValue(
      userOrdersDBRef,
      (snapshot) => {
        const data = snapshot.val()

        handleUserOrders(data)
      },
      (error) => {
        console.error(error)
      },
    )

    return userOrdersDBRef
  }

  static adminUnregisterUserFromAllowedWorkshop = async (firebaseApp, sessionId, conferenceId, uid) => {
    const database = getDatabase(firebaseApp)
    const registrationSessionRef = ref(database, `/registrations/sessions/${sessionId}`)

    await update(
      ref(database, `/registrations/users-registrations/${uid}/${conferenceId}/${sessionId}/registration`),
      {
        status: 'waiting',
      },
    )

    const transactionWithUpdate = await runTransaction(registrationSessionRef, (item) => {
      if (item) {
        const allowedList = item.allowedList || {}
        const userAllowedListTimestamp = allowedList?.[uid]?.timestamp ?? Date.now()

        delete allowedList[uid]

        const waitingList = item.waitingList || {}

        waitingList[uid] = {
          uid,
          timestamp: userAllowedListTimestamp,
        }

        item.waitingList = waitingList
        item.allowedList = allowedList

        return item
      }

      return item
    })

    return transactionWithUpdate.committed
  }

  static adminRegisterUserToWorkshop = async (firebaseApp, sessionId, conferenceId, uid) => {
    const database = getDatabase(firebaseApp)
    const registrationSessionRef = ref(database, `/registrations/sessions/${sessionId}`)

    await update(
      ref(database, `/registrations/users-registrations/${uid}/${conferenceId}/${sessionId}/registration`),
      {
        status: 'approved',
      },
    )

    const transactionWithUpdate = await runTransaction(registrationSessionRef, (item) => {
      if (item) {
        const waitingList = item.waitingList || {}
        const userWaitingListTimestamp = waitingList?.[uid]?.timestamp ?? Date.now()

        delete waitingList[uid]

        const allowedList = item.allowedList || {}

        allowedList[uid] = {
          uid,
          timestamp: userWaitingListTimestamp,
        }

        item.allowedList = allowedList
        item.waitingList = waitingList

        return item
      }

      return item
    })

    return transactionWithUpdate.committed
  }

  static observeAllFavouriteSessions = (firebaseApp, uid, handleFavouriteSessions) => {
    const favouriteSessionsDBRef = ref(getDatabase(firebaseApp), `/favouriteSessions/${uid}`)

    onValue(
      favouriteSessionsDBRef,
      (snapshot) => {
        const data = snapshot.val()
        const values = data ? Object.values(data) : []

        handleFavouriteSessions(values)
      },
      (error) => {
        console.error(error)
      },
    )

    return favouriteSessionsDBRef
  }

  static getUserNotes = async (firebaseApp, uid) => {
    const notesSnap = await get(ref(getDatabase(firebaseApp), `/notes/${uid}`))

    if (!notesSnap.exists()) {
      return []
    }

    const notes = notesSnap.val()

    return notes ? Object.values(notes) : []
  }

  // In case that user not exists yet in firebase this method will return object with uid
  static getUserProfile = async (firebaseApp, uid) => {
    const userProfile = await get(ref(getDatabase(firebaseApp), `/users/${uid}`))

    const user = userProfile.exists() ? userProfile.val() : {}
    user.uid = uid

    return user
  }

  static saveUserDetails = (firebaseApp, uid, details) => {
    update(ref(getDatabase(firebaseApp), `/users-details/${uid}`), details)
  }

  static getFavouriteSession = async (firebaseApp, uid, sessionId) => {
    const noteSnap = await get(ref(getDatabase(firebaseApp), `/favouriteSessions/${uid}/${sessionId}`))

    return noteSnap.exists()
  }

  static removeFavouriteSession = (firebaseApp, uid, sessionId, callback) => {
    if (!callback) callback = () => {}
    remove(ref(getDatabase(firebaseApp), `/favouriteSessions/${uid}/${sessionId}`)).finally(() => callback())
  }

  static setFavouriteSession = (firebaseApp, uid, sessionId, conferenceId, callback) => {
    if (!callback) callback = () => {}

    set(ref(getDatabase(firebaseApp), `/favouriteSessions/${uid}/${sessionId}`), {
      uid,
      sessionId,
      conferenceId,
    }).finally(() => callback())
  }

  static saveSessionNote = (firebaseApp, uid, sessionId, note) => {
    set(ref(getDatabase(firebaseApp), `/notes/${uid}/${sessionId}`), {
      uid,
      sessionId,
      note,
    })
  }

  static getSessionNote = async (firebaseApp, uid, sessionId) => {
    const noteSnap = await get(ref(getDatabase(firebaseApp), `/notes/${uid}/${sessionId}`))

    const data = noteSnap.val()

    return data ? data.note : ''
  }

  static setStageStream = (firebaseApp, conferenceId, stageId, stream) => {
    set(ref(getDatabase(firebaseApp), `/conferences/${conferenceId}/streams/${stageId}`), stream)
    // save it to firestore
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-saveLiveStreamToFirestore`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ streamUrl: stream, conferenceId, stageId }),
      }).then((res) => res.json())
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static getStageStreams = async (firebaseApp, conferenceId) => {
    const streamSnap = await get(ref(getDatabase(firebaseApp), `/conferences/${conferenceId}`))
    const assets = streamSnap.val()
    return {
      streams: assets?.streams || {},
      assets: assets?.assets || {},
    }
  }
  static getStageStream = async (firebaseApp, conferenceId, stageId) => {
    const streamSnap = await get(ref(getDatabase(firebaseApp), `/conferences/${conferenceId}`))
    const assets = streamSnap.val()
    return {
      stream: assets?.streams?.[stageId],
      asset: assets?.assets?.[stageId],
    }
  }

  static updateUser = (firebaseApp, uid, value) => {
    return update(ref(getDatabase(firebaseApp), `/users/${uid}`), {
      ...value,
    })
  }

  static uploadSubtitles = async (firebaseApp, subtitlesFile: File): Promise<string | null> => {
    return await new Promise((resolve) => {
      const encodedFileName = window.btoa(subtitlesFile.name)
      const ref = storageRef(
        getStorage(firebaseApp, 'gs://uxdx_subtitles'),
        `/subtitles/${encodedFileName}.srt`,
      )
      const uploadTask = uploadBytesResumable(ref, subtitlesFile)

      uploadTask.on(
        'state_changed',
        () => {},
        () => resolve(null),
        () => {
          ApiGateway.getMuxSubtitlesPublicUrl(`subtitles/${encodedFileName}.srt`)
            .then(({ publicURL }) => {
              resolve(publicURL)
            })
            .catch(() => resolve(null))
        },
      )
    })
  }

  static removeSubtitles = async (firebaseApp, fileName) => {
    return await new Promise((resolve, reject) => {
      const encodedFileName = window.btoa(fileName)
      const objectRef = storageRef(
        getStorage(firebaseApp, 'gs://uxdx_subtitles'),
        `/subtitles/${encodedFileName}.srt`,
      )

      deleteObject(objectRef)
        .then(() => {
          resolve('Success')
        })
        .catch(() => {
          reject(`Error during removing file: ${fileName}`)
        })
    })
  }

  static uploadProfileImage = async (firebaseApp, file, user) => {
    return await new Promise((resolve, reject) => {
      const ref = storageRef(getStorage(firebaseApp), `/${user.uid}`)
      const uploadTask = uploadBytesResumable(ref, file)

      uploadTask.on(
        'state_changed',
        () => {
          // Observe progress
        },
        () => {
          reject(null)
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL)
          })
        },
      )
    })
  }

  static createMuxLiveStream = ({ streamName }) => {
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-createLiveStream`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ streamName }),
      }).then((res) => res.json())
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static getMuxSubtitlesPublicUrl = (fileName) => {
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-getSubtitlesPublicUrl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
      }).then((res) => res.json())
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static addSubtitlesToAsset = (assetId, subtitlesUrl) => {
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-addSubtitlesToAsset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ assetId, subtitlesUrl }),
      }).then((res) => res.json())
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static getMuxVideoToken = async (playbackId) => {
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-getToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ playbackId }),
      }).then((res) => res.json())
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static getMuxUploadURL = (setUploadId) => async () => {
    try {
      const bodyOptions = {
        signed: true,
      }

      return fetch(`${ApiGateway.getHostname()}muxUpload-getUploadURL`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyOptions),
      })
        .then((res) => res.json())
        .then(({ id, url }) => {
          setUploadId(id)
          return url
        })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static muxUploadWithURL = (selectedFileURL: string, selectedSubtitlesURL?: string) => {
    try {
      type MuxUploadWithUrlBodyOptions = {
        signed: boolean
        videoUrl: string
        subtitlesUrl?: string
      }

      const bodyOptions: MuxUploadWithUrlBodyOptions = {
        signed: true,
        videoUrl: selectedFileURL,
      }

      if (selectedSubtitlesURL) {
        bodyOptions.subtitlesUrl = selectedSubtitlesURL
      }

      return fetch(`${ApiGateway.getHostname()}muxUpload-uploadURL`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyOptions),
      }).then((res) => res.json())
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static getMuxVideoAsset = async (id) => {
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-getVideoAsset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      }).then((res) => res.json())
    } catch (e) {
      console.error('Error in getMuxVideoAsset', e)
    }
  }

  static getMuxVideoUpload = async (id) => {
    try {
      return fetch(`${ApiGateway.getHostname()}muxUpload-getVideoUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      }).then((res) => res.json())
    } catch (e) {
      console.error('Error in getMuxVideoUpload', e)
    }
  }

  static getTicket = async (firebaseApp, email, ticketRef, userId) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)
    const data = {
      ticketRef,
      email: trim(email),
      uid: userId,
      idToken,
      requesterUid: currentUser.uid,
      requesterLocation: 'Account - Get Ticket',
    }
    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-getTicket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
        oldId: jsonData.oldId,
        ticket: jsonData.ticket,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static syncTicketFromFirestoreToFirebase = async (firebaseApp, user) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)
    const data = {
      email: user.email,
      userId: user.uid,
      idToken,
    }
    try {
      const response = await fetch(
        `${ApiGateway.getHostname()}getTicket-syncTicketsFromFirestoreToFirebase`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      )
      const jsonData = await response.json()
      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static syncOrdersFromFirestoreToFirebase = async (firebaseApp, user) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)
    const data = {
      email: user.email,
      userId: user.uid,
      idToken,
    }
    try {
      const response = await fetch(
        `${ApiGateway.getHostname()}orderManagement-syncOrdersFromFirestoreToFirebase`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      )
      const jsonData = await response.json()
      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  // used on the order/ticketmgmt page to load a ticket
  static findTicket = async (ticketRef) => {
    const data = {
      ticketRef,
    }
    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-findTicket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const jsonData: FindTicketApiResponse = await response.json()
      const apiResponse: FindTicketResponse = {
        code: response.status,
        message: jsonData.message,
        ticket: jsonData.ticket,
      }
      return apiResponse
    } catch (error) {
      if (error instanceof FirebaseError) {
        const apiResponse: FindTicketResponse = {
          code: 500,
          error,
          message: error.message,
        }
        return apiResponse
      }

      const apiResponse: FindTicketResponse = {
        code: 500,
        message: 'Error occurred',
      }
      return apiResponse
    }
  }

  static transferWorkshops = async (firebaseApp, oldUserId, conferenceId, newUserId) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)
    const data = {
      oldUserId,
      conferenceId,
      newUserId,
      idToken,
    }
    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-transferWorkshops`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static getSessionFeedback = async (firebaseApp, uid, sessionId) => {
    const noteSnap = await get(ref(getDatabase(firebaseApp), `/feedback/${sessionId}/${uid}`))

    return noteSnap.exists() ? noteSnap.val() : null
  }

  static setSessionFeedback = (firebaseApp, uid, sessionId, recordId, feedback) => {
    return set(ref(getDatabase(firebaseApp), `/feedback/${sessionId}/${uid}`), {
      ...feedback,
      sessionId,
      recordId,
      timestamp: Date.now(),
    })
  }

  static addUserTicketQuestions: AddTicketQuestionsFunction = (firebaseApp, uid, ticketRef, payload) => {
    return set(ref(getDatabase(firebaseApp), `/ticketsDetails/${uid}/${ticketRef}`), payload)
  }

  static getUserTicketQuestions: GetTicketQuestionsFunction = async (firebaseApp, uid, ticketRef) => {
    const snapshot = await get(ref(getDatabase(firebaseApp), `/ticketsDetails/${uid}/${ticketRef}`))
    const ticketQuestions = snapshot.exists() ? snapshot.val() : ([] as TicketQuestion[])
    return ticketQuestions
  }

  static setTicketCompleted = async (ticketRef, userId, value) => {
    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-setTicketCompleted`, {
        method: 'POST',
        headers: {
          // 'Content-Type':'application/x-www-form-urlencoded',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ticketRef,
          completed: value,
          userId,
        }),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static updateSessionFeedback = (firebaseApp, uid, sessionId, feedback) => {
    return update(ref(getDatabase(firebaseApp), `/feedback/${sessionId}/${uid}`), {
      ...feedback,
      timestamp: Date.now(),
    })
  }

  static getRegistrationSession = async (firebaseApp, sessionId) => {
    const registrationSessionsSnap = await get(
      ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`),
    )

    return registrationSessionsSnap.exists() ? registrationSessionsSnap.val() : null
  }

  static getAllRegistrationSessions = async (firebaseApp, conferenceId) => {
    const workshopParticipantsSnap = await get(
      query(
        ref(getDatabase(firebaseApp), '/registrations/sessions'),
        ...[orderByChild('conferenceId'), startAt(conferenceId), endAt(conferenceId)],
      ),
    )

    return workshopParticipantsSnap.exists() ? workshopParticipantsSnap.val() : {}
  }

  static registerUserInPersonActivitySession = async (
    firebaseApp,
    uid,
    conferenceId,
    sessionId,
    sessionCapacity,
    recordId,
    calendarId,
    startTime,
    sessionName,
    sessionSlug,
    sessionType,
  ) => {
    const sessionRef = ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`)

    const transactionWithUpdate = await runTransaction(sessionRef, (item) => {
      if (item) {
        if (!item.userUidslist) {
          item.userUidslist = {
            [uid]: true,
          }
          item.used = 1
        } else {
          item.userUidslist[uid] = true
          item.used++
        }

        return item
      }

      return {
        conferenceId,
        used: 1,
        userUidslist: {
          [uid]: true,
        },
        sessionType,
        capacity: sessionCapacity,
        recordId,
        calendarId,
        startTime,
        sessionName,
        sessionSlug,
      }
    })

    return transactionWithUpdate.committed
  }

  static unregisterUserInPersonActivitySession = async (firebaseApp, uid, sessionId) => {
    const sessionRef = ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`)

    const transactionWithUpdate = await runTransaction(sessionRef, (item) => {
      if (item && item.userUidslist) {
        delete item.userUidslist[uid]
        item.used--

        return item
      }

      return item
    })

    return transactionWithUpdate.committed
  }

  static registerUserWorkshopSession = async (
    firebaseApp,
    conferenceId,
    sessionId,
    sessionCapacity,
    recordId,
    calendarId,
    startTime,
    sessionName,
    sessionSlug,
  ) => {
    const session = await ApiGateway.getRegistrationSession(firebaseApp, sessionId)
    const isEligible = session ? session.used < session.capacity : true

    if (!isEligible) {
      return false
    }

    const sessionRef = ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`)

    const transactionWithUpdate = await runTransaction(sessionRef, (item) => {
      if (item) {
        if (item.used + 1 <= item.capacity) {
          item.used++
          item.capacity = sessionCapacity

          return item
        } else {
          // Break transaction
          return
        }
      }

      return {
        conferenceId,
        productId: conferenceId,
        capacity: sessionCapacity,
        used: 1,
        sessionType: 'Workshop',
        recordId,
        calendarId,
        startTime,
        sessionName,
        sessionSlug,
      }
    })

    return transactionWithUpdate.committed
  }

  // //FIXME: remove it - added just to copy to new structure
  // static getAllWorkshops = async (firebaseApp) => {
  //   const workshopsRef = await get(ref(getDatabase(firebaseApp), `/workshops`))
  //
  //   return workshopsRef.val()
  // }
  //
  // static getAllWorkshopsParticipants = async (firebaseApp) => {
  //   const workshopsRef = await get(ref(getDatabase(firebaseApp), `/workshopParticipants`))
  //
  //   return workshopsRef.val()
  // }
  //
  // static setAllWorkshops = async (firebaseApp, payload) => {
  //   await set(ref(getDatabase(firebaseApp), `/registrations/users-registrations`), payload)
  // }
  //
  // static setAllWorkshopsParticipants = async (firebaseApp, payload) => {
  //   await set(ref(getDatabase(firebaseApp), `/registrations/sessions`), payload)
  // }

  static unregisterFromSession = async (firebaseApp, sessionId) => {
    const sessionRef = ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`)

    const transactionWithUpdate = await runTransaction(sessionRef, (item) => {
      if (item) {
        if (item.used - 1 <= 0) {
          item.used = 0
        } else {
          item.used--
        }
      }

      return item
    })

    return transactionWithUpdate.committed
  }

  static unregisterFromApprovedList = async (firebaseApp, sessionId, uid) => {
    const sessionRef = ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`)

    const transactionWithUpdate = await runTransaction(sessionRef, (item) => {
      if (item) {
        const allowedList = item.allowedList || {}
        delete allowedList[uid]

        item.allowedList = allowedList

        return item
      }

      return item
    })

    return transactionWithUpdate.committed
  }

  static addUserRegistration = async (
    firebaseApp,
    uid,
    conferenceId,
    sessionId,
    registration,
    sessionType,
    calendarId,
    startTime,
    sessionName,
    sessionSlug,
    sessionRecordId,
  ) => {
    const registrationData = {
      ...registration,
      sessionId,
      conferenceId,
      productId: conferenceId,
      uid,
      sessionType,
      calendarId,
      startTime,
      sessionName,
      sessionSlug,
      airtableSessionRecordId: sessionRecordId,
    }

    await set(
      ref(getDatabase(firebaseApp), `/registrations/users-registrations/${uid}/${conferenceId}/${sessionId}`),
      registrationData,
    )

    return registrationData
  }

  static removeUserRegistration = async (firebaseApp, uid, conferenceId, sessionId) => {
    await remove(
      ref(getDatabase(firebaseApp), `/registrations/users-registrations/${uid}/${conferenceId}/${sessionId}`),
    )

    return null
  }

  static getUserRegistration = async (firebaseApp, uid, conferenceId, sessionId) => {
    try {
      const userWorkshop = await get(
        ref(
          getDatabase(firebaseApp),
          `/registrations/users-registrations/${uid}/${conferenceId}/${sessionId}`,
        ),
      )

      return userWorkshop.exists() ? userWorkshop.val() : null
    } catch {
      return null
    }
  }

  static getUserRegistrations = async (firebaseApp, uid) => {
    const workshopsRef = await get(ref(getDatabase(firebaseApp), `/registrations/users-registrations/${uid}`))

    const workshops = workshopsRef.exists() ? workshopsRef.val() : null

    return workshops !== null ? Object.values(workshops) : []
  }

  static observeAllUserRegistrations = (firebaseApp, uid, handleWorkshops) => {
    const workshopsRef = ref(getDatabase(firebaseApp), `/registrations/users-registrations/${uid}`)

    onValue(
      workshopsRef,
      (snapshot) => {
        const data = snapshot.val()
        const values = data ? Object.values(data) : []

        handleWorkshops(values)
      },
      (error) => {
        console.error(error)
      },
    )

    return workshopsRef
  }

  static observeUserRegistrations = (firebaseApp, uid, conferenceId, handleRegistrations) => {
    const registrationsRef = ref(
      getDatabase(firebaseApp),
      `/registrations/users-registrations/${uid}/${conferenceId}`,
    )

    onValue(
      registrationsRef,
      (snapshot) => {
        const data = snapshot.val()
        const values = data ? Object.values(data) : []

        handleRegistrations(values)
      },
      (error) => {
        console.error(error)
      },
    )

    return registrationsRef
  }

  static registerToWaitingList = async (
    firebaseApp,
    uid,
    conferenceId,
    sessionId,
    sessionCapacity,
    sessionType,
    recordId,
    calendarId,
    startTime,
    sessionName,
    sessionSlug,
  ) => {
    const workshopsRef = ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}`)

    await runTransaction(workshopsRef, (item) => {
      if (item) {
        // Break transaction
        return
      }

      // Create session if not exists
      return {
        conferenceId,
        capacity: sessionCapacity,
        recordId,
        used: 0,
        sessionType,
        calendarId,
        productId: conferenceId,
        startTime,
        sessionName,
        sessionSlug,
      }
    })

    return await set(
      ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}/waitingList/${uid}`),
      {
        uid,
        timestamp: Date.now(),
      },
    )
  }

  static unregisterFromWaitingList = async (firebaseApp, sessionId, uid) => {
    return await remove(
      ref(getDatabase(firebaseApp), `/registrations/sessions/${sessionId}/waitingList/${uid}`),
    )
  }

  static sendEmail = async (data: MailOptions) => {
    try {
      const response = await fetch(`${ApiGateway.getHostname()}sendMail-sendMail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static sendAssignOrderReminder = async (firebaseApp, data) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      data.idToken = await getIdToken(currentUser, true)

      const response = await fetch(`${ApiGateway.getHostname()}sendMail-sendOrderAssignReminderEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }
  static sendTicketAccountReminderEmail = async (firebaseApp, data) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      data.idToken = await getIdToken(currentUser, true)

      const response = await fetch(`${ApiGateway.getHostname()}sendMail-sendTicketAccountReminderEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static getUserClaims = async (firebaseApp) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idTokenResult = await getIdTokenResult(currentUser, true)

    return idTokenResult.claims
  }

  static getUsers = async (firebaseApp, uids) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)

    try {
      const response = await fetch(`${ApiGateway.getHostname()}login-getUsers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idToken,
          uids,
        }),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        // message: response.message, // typescript checked response.json() type and there is no message on it
        users: jsonData.users,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static getAllUsers = async (firebaseApp, withDetails) => {
    const usersSnapshot = await get(ref(getDatabase(firebaseApp), '/users'))
    const usersDetailsSnapshot = withDetails
      ? await get(ref(getDatabase(firebaseApp), '/users-details'))
      : null

    const usersObject = usersSnapshot.val()
    const usersDetailsObject = usersDetailsSnapshot ? usersDetailsSnapshot.val() : null

    for (const uid in usersObject) {
      const user = usersObject?.[uid] ?? null

      if (!user) {
        continue
      }

      user.uid = uid

      if (usersDetailsObject) {
        const userDetails = usersDetailsObject?.[uid] ?? null

        if (userDetails) {
          user.email = userDetails.email
          if (userDetails.claims) {
            user.claims = userDetails.claims
          }
        }
      }
    }

    return usersObject
  }

  // static getAllAdmins = async (firebaseApp, roomId, users = []) => {
  //   const getUsers = async() => {
  //     try {
  //       const response = await fetch(`${ApiGateway.getHostname()}login-getAllAdmins`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           idToken,
  //           nextPageToken: pageToken
  //         })
  //       })
  //       const jsonData = await response.json()

  //       return {
  //         code: response.status,
  //         users: jsonData.users,
  //         pageToken: jsonData.pageToken
  //       }
  //     } catch (e) {
  //       return {
  //         error: e,
  //         code: 400,
  //         message: e.message
  //       }
  //     }
  //   }
  //   const response = await getUsers()
  //   users = users.concat(response.users)
  //   if (response.pageToken) return this.getAllUsers(firebase, response.pageToken, users)
  //   let filteredUsers
  //   if (users) {
  //     filteredUsers = users.filter((user) => {
  //       return user && user.claims && user.claims.rooms && user.claims.rooms[roomId]
  //     })
  //   }
  //   return filteredUsers
  // }

  // static getRoomAdmins = async (firebase, roomId) => {
  //   const response = await this.getAllUsers(firebase)
  //   let filteredUsers
  //   if (response.users) {
  //     filteredUsers = response.users.filter((user) => {
  //       return user && user.claims && user.claims.rooms && user.claims.rooms[roomId]
  //     })
  //   }
  //   return filteredUsers
  // }

  static getRoomAdmins = async (firebaseApp, roomId) => {
    const usersSnapshot = await get(
      query(ref(getDatabase(firebaseApp), '/users'), ...[orderByChild('claims')]),
    )
    const usersObject = usersSnapshot.val()
    const users: any[] = Object.values(usersObject)
    const userIds = Object.keys(usersObject)
    users.forEach((user, index) => {
      user.uid = userIds[index]
    })
    let filteredUsers
    if (users) {
      filteredUsers = users.filter((user) => {
        return user && user.claims && user.claims.rooms && user.claims.rooms[roomId]
      })
    }
    return filteredUsers
  }

  static setUserClaims = async (firebaseApp, uid, claim, claimValue) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)
    const data = {
      userId: uid,
      claim,
      claimValue,
      idToken,
    }
    try {
      const response = await fetch(`${ApiGateway.getHostname()}login-setAdmin`, {
        method: 'POST',
        headers: {
          // 'Content-Type':'application/x-www-form-urlencoded',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static getWorkshopRegistrations = async (firebaseApp, conferenceId) => {
    const data = {
      conferenceId,
    }

    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-getWorkshopAttendees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        registrations: jsonData.registrations,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static updateTicketSessionLimits = async (firebaseApp, userId, sessionLimit, ticketRef) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)

    try {
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-updateSessionLimit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          ticketRef,
          sessionLimit,
          idToken,
        }),
      })
      const jsonData = await response.json()

      return {
        code: response.status,
        registrations: jsonData.registrations,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static verifyUser = async (firebaseApp, email) => {
    const timestamp = Date.now()
    try {
      await set(ref(getDatabase(firebaseApp), '/force/' + timestamp), { email })
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      }

      return error
    }
  }

  static changeUserEmail = async (firebaseApp, email, password) => {
    const currentUser = getAuth(firebaseApp).currentUser
    if (!currentUser) {
      return Promise.reject()
    }

    let user

    try {
      const userCredential = await signInWithEmailAndPassword(
        getAuth(firebaseApp),
        currentUser.email || '',
        password,
      )
      // Signed in
      user = userCredential.user
      const emailCredential = EmailAuthProvider.credential(currentUser.email || '', password)
      await reauthenticateWithCredential(user, emailCredential)
    } catch (error) {
      if (error instanceof Error) {
        return {
          error,
          code: 400,
          message: error.message,
          source: 're-authenticating user',
        }
      } else {
        // Handle other types of errors or rethrow the error if you can't handle it.
        // You can also return a generic error message here.
        throw error
      }
    }
    try {
      await updateEmail(user, email)
      return {
        code: 200,
        message: 'Email updated successfully!',
      }
    } catch (error) {
      if (error instanceof Error) {
        return {
          error,
          code: 400,
          message: error.message,
        }
      } else {
        // Handle other types of errors or rethrow the error if you can't handle it.
        // You can also return a generic error message here.
        throw error
      }
    }
  }

  static assignTicket = async (bodyOptions: AssignTicketOptions) => {
    try {
      // assign the ticket to the new user
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-assignTicket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyOptions), // body data type must match "Content-Type" header
      })
      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (error: any) {
      Sentry.captureMessage('Assign Failed:' + JSON.stringify(bodyOptions))
      Sentry.captureMessage(error.toString())
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error during assigning your tickets. Please contact us!'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  // static reassignTicket = async (bodyOptions: AssignTicketOptions) => {
  //   try {
  //     const response = await fetch(`${ApiGateway.getHostname()}getTicket-reassignTicket`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(bodyOptions), // body data type must match "Content-Type" header
  //     })

  //     const jsonData = await response.json()

  //     return {
  //       error: response.status >= 400,
  //       code: response.status,
  //       message: jsonData.message,
  //     }
  //   } catch (error) {
  //     const message =
  //       error instanceof FirebaseError
  //         ? error.message
  //         : 'There were an error during reassigning your tickets. Please contact us!'

  //     return {
  //       error: true,
  //       code: 400,
  //       message,
  //     }
  //   }
  // }

  // static assignUserToOrder = async (firebaseApp, orderId, uid, email) => {
  //   // find the uid for the email
  //   const orderDBRef = ref(getDatabase(firebaseApp), `/orders/all/${orderId}`)
  //   const userOrderDBRef = ref(getDatabase(firebaseApp), `/orders/userOrders/${uid}`)
  //   try {
  //     await Promise.all([update(orderDBRef, { email, uid }), push(userOrderDBRef, orderId)])
  //   } catch (e) {
  //     console.log(e)
  //   }
  //   ApiGateway.addOrderUser(orderId, uid)

  //   return
  // }

  static createTicket = async (firebaseApp, ticketData) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      const idToken = await getIdToken(currentUser, true)
      const promises: Promise<any>[] = []
      if (!Array.isArray(ticketData)) ticketData = [ticketData]
      ticketData.map((ticketData) => {
        const data = {
          ...ticketData,
          idToken,
        }
        promises.push(
          fetch(`${ApiGateway.getHostname()}getTicket-createTicket`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
          }),
        )
      })

      return Promise.all(promises)
        .then((responses) => responses.map((r) => r.json()))
        .then((ticketResponses) => {
          return {
            error: ticketResponses.some((r) => r.error),
            code: ticketResponses.some((r) => r.code >= 400) ? 400 : 200,
            message: ticketResponses.some((r) => r.message)
              ? ticketResponses.map((r) => r.message).join('\n')
              : '',
          }
        })
    } catch (error) {
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error during creating your tickets. Please contact us!'

      return {
        error: true,
        error2: error,
        code: 400,
        message,
      }
    }
  }

  static createOrder = async (firebaseApp, orderData) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject({ code: 500, message: 'Not logged in' })
      }

      const idToken = await getIdToken(currentUser, true)
      const promises: Promise<any>[] = []
      if (!Array.isArray(orderData)) orderData = [orderData]
      orderData.map((orderData) => {
        const data = {
          ...orderData,
          idToken,
        }
        promises.push(
          fetch(`${ApiGateway.getHostname()}getTicket-createOrder`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
          }),
        )
      })
      return Promise.all(promises)
        .then((responses) => responses.map((r) => r.json()))
        .then((ticketResponses) => {
          return {
            error: ticketResponses.some((r) => r.error),
            code: ticketResponses.some((r) => r.code >= 400) ? 400 : 200,
            message: ticketResponses.some((r) => r.message)
              ? ticketResponses.map((r) => r.message).join('\n')
              : '',
          }
        })
    } catch (error) {
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error during creating your tickets. Please contact us!'

      return {
        error: true,
        error2: error,
        code: 400,
        message,
      }
    }
  }

  static saveOrderUser = async (firebaseApp, orderId) => {
    const currentUser = getAuth(firebaseApp).currentUser

    if (!currentUser) {
      return Promise.reject()
    }

    const idToken = await getIdToken(currentUser, true)
    const data = {
      email: currentUser.email,
      userId: currentUser.uid,
      orderId,
      idToken,
    }
    try {
      const response = await fetch(`${ApiGateway.getHostname()}orderManagement-saveOrderUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error assigning user to order. Please contact us!'

      return {
        error: true,
        error2: error,
        code: 400,
        message,
      }
    }
  }

  static cancelOrder = async (firebaseApp, orderId, cancelTickets, env) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      const idToken = await getIdToken(currentUser, true)
      const data = {
        orderId,
        userId: currentUser.uid,
        idToken,
        cancelTickets,
        env,
      }
      const response = await fetch(`${ApiGateway.getHostname()}orderManagement-cancelOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError ? error.message : 'There were an error during cancelling your order.'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static markPaidOrder = async (
    firebaseApp: FirebaseApp,
    orderId: string,
    invoiceId: string,
    env: string,
  ) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      const idToken = await getIdToken(currentUser, true)
      const data = {
        orderId,
        userId: currentUser.uid,
        idToken,
        invoiceId,
        env,
      }
      // console.log('request data', data)
      const response = await fetch(`${ApiGateway.getHostname()}orderManagement-markPaidOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError ? error.message : 'There were an error marking your order as paid.'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static uncancelOrder = async (firebaseApp, orderId) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      const idToken = await getIdToken(currentUser, true)
      const data = {
        orderId,
        userId: currentUser.uid,
        idToken,
      }
      const response = await fetch(`${ApiGateway.getHostname()}orderManagement-uncancelOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error during uncancelling your tickets.'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static sendOrderConfirmationEmail = (order) => {
    fetch('https://us-central1-uxdx-48a34.cloudfunctions.net/sendMail-sendOrderCompletedEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...order,
      }), // body data type must match "Content-Type" header
    })
  }

  static generateInvoice = (order) => {
    fetch('https://us-central1-uxdx-48a34.cloudfunctions.net/getTicket-getInvoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId: order.orderId,
      }), // body data type must match "Content-Type" header
    })
  }

  static voidTicket = async (firebaseApp, ticketRef, voidStatus) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      const idToken = await getIdToken(currentUser, true)
      const data = {
        ticketRef,
        voidStatus,
        idToken,
      }
      const response = await fetch(`${ApiGateway.getHostname()}getTicket-voidTicket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError ? error.message : 'There were an error during cancelling your tickets.'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static sendTicketConfirmationEmail = async (firebase, ticket, conference) => {
    const getHotels = async (confId) => {
      const encodedFormula = encodeURIComponent(
        `AND(
        FIND("${confId}", ARRAYJOIN({ConfIds})),
        {Recommended Hotel}=1,
        Status="Live"
    )`,
      )
      const response = await fetch(
        `https://api.airtable.com/v0/app30LHaP4IFZaCTN/tbl6gya9eKbY5FPyn?filterByFormula=${encodedFormula}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + process.env.AIRTABLE_API_KEY,
            'Content-Type': 'application/json',
          },
        },
      )
      const data = await response.json()

      const hotelsArray = data.records.map((record) => ({
        Hotel: record.fields.Hotel, // Replace 'Hotel' with the actual field name
        Address: record.fields.Address, // Replace 'Address' with the actual field name
        DiscountCode: record.fields['Discount Code'], // Replace 'Discount Code' with the actual field name
        DiscountPercentage: record.fields['Discount Percentage'], // Replace 'Discount Percentage' with the actual field name
        DiscountInstructions: record.fields['Discount Instructions'], // Replace 'Discount Percentage' with the actual field name
        BookingLink: record.fields['Booking Link'], // Replace 'Booking Link' with the actual field name
        PhoneNumber: record.fields['Phone Number'], // Replace 'Phone Number' with the actual field name
        ImageURL: record.fields['imageUrl'], // Replace 'Phone Number' with the actual field name
      }))
      return hotelsArray
    }
    ticket.hotels = await getHotels(ticket.productId)
    ticket.workshopDay = conference.data.Workshop_Day
    let url = ''
    if (ticket.completed) {
      url = 'https://us-central1-uxdx-48a34.cloudfunctions.net/sendMail-sendTicketCompletedEmail'
    }
    if (!ticket.completed)
      url = 'https://us-central1-uxdx-48a34.cloudfunctions.net/sendMail-sendTicketAssignedEmail'
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...ticket,
      }), // body data type must match "Content-Type" header
    })
  }

  static scanBadge = async (firebaseApp, ticketRef, scannerUid) => {
    try {
      const ticketDoc = await getDoc(doc(getFirestore(), 'tickets', ticketRef))
      if (!ticketDoc.exists()) {
        throw 'The list does not exist'
      }
      const ticket = ticketDoc.data()
      const uid = ticket.uid

      let email, company, jobTitle, linkedIn, first, last, displayName
      const userData = {}

      if (!uid) {
        ;(email = ticket.email),
          (company = ticket.company),
          (jobTitle = ticket.jobTitle),
          (first = ticket.firstName),
          (last = ticket.lastName),
          (displayName = first + ' ' + last)
      } else {
        //get the user details from firebase database

        const userProfile = await get(ref(getDatabase(firebaseApp), `/users/${uid}`))

        const userData = userProfile.exists() ? userProfile.val() : {}

        email = userData?.email || null
        company = userData?.company || ticket.company || null
        jobTitle = userData?.jobTitle || ticket.jobTitle || null
        linkedIn = userData?.linkedin
        first = userData?.firstName || ticket.firstName || null
        last = userData?.lastName || ticket.lastName || null
        displayName = first + ' ' + last
      }

      try {
        const scanDocRef = doc(getFirestore(), 'scans/', scannerUid)
        setDoc(scanDocRef, { uid: scannerUid }, { merge: true })
        // Set the data, merging with any existing data

        const connectionsRef = collection(getFirestore(), 'scans', scannerUid, 'connections')

        // Add a new document to the collection
        const data = {
          ticketRef,
          created: serverTimestamp(),
          email: email,
          displayName: displayName,
          company: company || '',
          jobTitle: jobTitle || '',
          linkedIn: linkedIn || '',
          productName: ticket.productName,
          productId: ticket.productId,
          timezone: ticket.productTimezone || '',
          id: '',
        }
        const docRef = await addDoc(connectionsRef, data)

        data.id = docRef.id

        // console.log('Connection added with ID:', docRef.id)

        return {
          error: false,
          code: 200,
          data,
          message: 'Connection added successfully',
        }
      } catch (e) {
        console.error('Error adding connection:', e)
        throw e
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error scanning the badge. If this persists then please contact support.'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static updateScanNote = async (scannerUid, scanId, updatedNote) => {
    try {
      // Get a reference to the document
      const docRef = doc(getFirestore(), 'scans', scannerUid, 'connections', scanId)

      // Update the note field in the document
      await updateDoc(docRef, { note: updatedNote })

      // console.log('Note updated successfully')
    } catch (error) {
      console.error('Error updating note:', error)
    }
  }

  static getScans = async (firebaseApp, uid) => {
    try {
      const query = firestoreQuery(collection(getFirestore(), 'scans', uid, 'connections'))
      const querySnapshot = await getDocs(query)
      const results: any[] = []

      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push({ ...doc.data(), id: doc.id })
      })

      return results
    } catch (error) {
      const message =
        error instanceof FirebaseError
          ? error.message
          : 'There were an error during looking up a users scans.'
      return {
        error: true,
        code: 400,
        message,
      }
    }
  }
  static getAllScans = async (productId) => {
    try {
      const scansRef = collection(getFirestore(), 'scans')
      const query = firestoreQuery(scansRef)
      const querySnapshot = await getDocs(query)
      const scansArray: any[] = []

      // console.log(querySnapshot.size)

      // Iterate over each uid document
      // querySnapshot.forEach(async (docSnapshot) => {
      //   const uid = docSnapshot.id
      //   console.log(uid)

      //   // For each uid document, query the collections subcollection
      //   const collectionsRef = collection(scansRef, `${uid}/collections`)
      //   const collectionsSnapshot = await getDocs(collectionsRef)

      //   console.log('collection', collectionsSnapshot.size)

      //   // Iterate over each scanId document in collections subcollection
      //   collectionsSnapshot.forEach((scanSnapshot) => {
      //     const scanId = scanSnapshot.id
      //     const scanData = scanSnapshot.data()

      //     // Push scan data to array, adding keys for uid and scanId
      //     scansArray.push({
      //       ...scanData,
      //       scannerId: uid,
      //       objectId: scanId,
      //     })
      //   })
      // })

      // return querySnapshot.forEach((scanDoc) => {
      //   // Access the "connections" subcollection for each "scan" document
      //   const connectionsRef = collection(scanDoc.ref, 'connections')
      //   const query = firestoreQuery(connectionsRef, where('productId', '==', productId))
      //   return getDocs(query)
      //     .then((connectionsSnapshot) => {
      //       connectionsSnapshot.forEach((connectionDoc) => {
      //         const connectionData = connectionDoc.data()
      //         scansArray.push({ ...connectionData, scannerId: scanDoc.id, objectId: connectionDoc.id })
      //         // console.log('Connection Data:', connectionData)
      //       })
      //       console.log(scansArray.length)
      //       // const flattenedScans = scansArray.reduce((acc, curr) => acc.concat(curr), [])

      //       return scansArray
      //     })
      //     .catch((error) => {
      //       throw Error('Error getting connections:' + error.message)
      //     })
      // })
      // Map over each scan document, get its connections, and add them to the array
      const scanPromises = querySnapshot.docs.map(async (scanDoc) => {
        // Access the "connections" subcollection for each "scan" document
        const connectionsRef = collection(scanDoc.ref, 'connections')
        const query = firestoreQuery(connectionsRef, where('productId', '==', productId))
        const connectionsSnapshot = await getDocs(query)

        connectionsSnapshot.forEach((connectionDoc) => {
          const connectionData = connectionDoc.data()
          scansArray.push({ ...connectionData, scannerId: scanDoc.id, objectId: connectionDoc.id })
          // console.log('Connection Data:', connectionData)
        })
        // console.log(scansArray.length)
        // const flattenedScans = scansArray.reduce((acc, curr) => acc.concat(curr), [])

        return scansArray
      })

      // Wait for all promises to resolve
      await Promise.all(scanPromises)

      // Return the array
      return scansArray
    } catch (error) {
      const message =
        error instanceof FirebaseError ? error.message : 'There were an error during looking up scans.'
      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static getProduct = async (firebaseApp, productId) => {
    try {
      const currentUser = getAuth(firebaseApp).currentUser

      if (!currentUser) {
        return Promise.reject()
      }

      const idToken = await getIdToken(currentUser, true)

      const data = {
        productId,
        idToken,
      }
      const response = await fetch(`${ApiGateway.getHostname()}productManagement-getProduct`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

      const jsonData = await response.json()

      return {
        error: response.status >= 400,
        code: response.status,
        message: jsonData.message,
        data: jsonData,
      }
    } catch (error) {
      const message =
        error instanceof FirebaseError ? error.message : 'There were an error getting the product.'

      return {
        error: true,
        code: 400,
        message,
      }
    }
  }

  static setCheckInListAdmin = (uid, listId, conferenceId) => {
    return setDoc(doc(getFirestore(), 'checkInLists', 'admins', uid, listId), { listId, conferenceId })
  }

  static removeCheckInListAdmin = (uid, listId) => {
    return deleteDoc(doc(getFirestore(), 'checkInLists', 'admins', uid, listId))
  }

  static addCheckInListForConference = async (conferenceId, listId, checkInList) => {
    const conferenceDocRef = doc(getFirestore(), 'checkInLists', 'conferences', conferenceId, listId)

    return firestoreRunTransaction(getFirestore(), async (transaction) => {
      const conferenceDoc = await transaction.get(conferenceDocRef)

      if (!conferenceDoc.exists()) {
        transaction.set(conferenceDocRef, {
          [listId]: checkInList,
        })
      } else {
        transaction.update(conferenceDocRef, {
          [listId]: checkInList,
        })
      }
    })
  }

  static removeCheckInListForConference = (conferenceId, listId) => {
    return deleteDoc(doc(getFirestore(), 'checkInLists', 'conferences', conferenceId, listId))
  }

  static observeCheckInListsForConference = (conferenceId, handleCheckInLists) => {
    const checkInListsQuery = firestoreQuery(
      collection(getFirestore(), 'checkInLists', 'conferences', conferenceId),
    )

    return onSnapshot(checkInListsQuery, (querySnapshot) => {
      const checkInLists = querySnapshot.docs.reduce((acc: CheckInList[], doc) => {
        const data = doc.data()
        if (data) {
          const value = Object.values(data) as CheckInList[]

          if (value.length) {
            acc.push(value[0])
          }
        }

        return acc
      }, [])

      handleCheckInLists(checkInLists)
    })
  }

  static observeCheckInListsUserPermissions = (uid, handlePermissions) => {
    const userPermissionsQuery = firestoreQuery(collection(getFirestore(), 'checkInLists', 'admins', uid))

    return onSnapshot(userPermissionsQuery, (querySnapshot) => {
      const permissions = querySnapshot.docs.reduce((acc: UserPermission[], doc) => {
        const data = doc.data() as UserPermission
        acc.push(data)

        return acc
      }, [])

      handlePermissions(permissions)
    })
  }

  static getConferenceCheckInList = async (conferenceId, listId) => {
    const listDoc = await getDoc(doc(getFirestore(), 'checkInLists', 'conferences', conferenceId, listId))

    if (!listDoc.exists()) {
      throw 'The list does not exist'
    }

    return listDoc.data()[listId]
  }

  static getAvailableTicketTypes = async (productId) => {
    const ticketsRef = collection(getFirestore(), 'tickets')
    const query = firestoreQuery(ticketsRef, where('productId', '==', productId))
    const querySnapshot = await getDocs(query)

    const results: any[] = []

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      results.push(doc.data())
    })

    return results.length ? Object.keys(groupBy(results, 'ticketType')) : []
  }

  static getCheckInListTicketsInfo = async (
    firebase: FirebaseApp,
    productId: string,
    includeConditions: string[],
  ) => {
    const ticketsRef = collection(getFirestore(), 'tickets')
    const query = firestoreQuery(
      ticketsRef,
      where('ticketType', 'in', includeConditions),
      where('productId', '==', productId),
    )
    const querySnapshot = await getDocs(query)

    // const results: Record<
    //   string,
    //   { email: string; tickets: Array<{ ticketId: string; ticketType: string; ticketRef: string }> }
    // > = {}
    const results: Array<any> = []

    // const users: Array<any> = []
    const tickets: Array<any> = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tickets.push(doc.data())
    })

    const userIds: Array<string> = []
    for (const ticket of tickets) {
      if (ticket.uid && userIds.indexOf(ticket.uid) === -1) {
        userIds.push(ticket.uid)
      }
    }

    const users = await Promise.all(userIds.map((uid) => ApiGateway.getUserProfile(firebase, uid)))

    for (const ticket of tickets) {
      let user
      if (ticket.uid) {
        // get teh user
        user = users.filter((user) => user.uid === ticket.uid)[0]
      }
      const ticketInfo = {
        ticketId: ticket.ticketId,
        ticketType: ticket.ticketType,
        ticketRef: ticket.ticketRef,
        firstName: user?.firstName || ticket.firstName,
        lastName: user?.lastName || ticket.lastName,
        jobTitle: user?.jobTitle || ticket.jobTitle,
        company: user?.company || ticket.company,
        email: ticket.email,
        qrcode: ticket.qrcode,
        // user,
      }

      // if (!results[ticket.uid]) {
      //   users.push(user)
      //   results[ticket.uid] = {
      //     email: ticket.email || 'not-provided',
      //     tickets: [ticketInfo],
      //   }
      // } else {
      //   results[ticket.uid].tickets.push(ticketInfo)
      // }
      results.push(ticketInfo)
    }

    return results
  }

  static getCheckedInTicketsByList = async (firebase: FirebaseApp, productId: string, listId: string) => {
    // get the collection list
    const checkInUserInfoRef = collection(
      getFirestore(),
      'checkInLists',
      'conferences',
      productId,
      listId,
      'ticketCheckIns',
    )
    const checkedInTickets = await getDocs(checkInUserInfoRef)

    return checkedInTickets
  }

  static updateTicketDetails = async (ticketDetails: ticketDetails) => {
    const { ticketId, firstName, lastName, jobTitle, company, history } = ticketDetails
    const ticketRef = doc(getFirestore(), 'tickets', ticketId)

    const payload = {
      firstName,
      lastName,
      jobTitle,
      company,
      history,
    }

    return firestoreRunTransaction(getFirestore(), async (transaction) => {
      transaction.update(ticketRef, payload)
      return true
    })
  }

  // static updateUserCheckedInInfo = async (productId: string, listId: string, uid: string, payload: any) => {
  //   const checkInUserInfoRef = doc(
  //     getFirestore(),
  //     'checkInLists',
  //     'conferences',
  //     productId,
  //     listId,
  //     ' ',
  //     uid,
  //   )

  //   return firestoreRunTransaction(getFirestore(), async (transaction) => {
  //     transaction.set(checkInUserInfoRef, payload)
  //     return true
  //   })
  // }
  static updateTicketCheckedInInfo = async (
    productId: string,
    listId: string,
    ticketRef: string,
    ticket: any,
  ) => {
    const checkInUserInfoRef = doc(
      getFirestore(),
      'checkInLists',
      'conferences',
      productId,
      listId,
      'ticketCheckIns',
      ticketRef,
    )

    return firestoreRunTransaction(getFirestore(), async (transaction) => {
      transaction.set(checkInUserInfoRef, ticket)
      return true
    })
  }

  // get the inPersonCount field for the inputted productId stored in firebase at /products/{productId}/inPersonCount

  static getInPersonCount = async (productId: string) => {
    const firestore = getFirestore()
    const productRef = doc(firestore, 'productCounts', productId)

    try {
      const productDoc = await getDoc(productRef)
      let inPersonCount = 0
      if (productDoc.exists()) {
        // Only extract the 'inPersonCount' field
        const productData = productDoc.data()
        inPersonCount = productData ? productData.inPersonCount : 0

        // incremenet workshop counter if the
      }

      return inPersonCount
    } catch (error) {
      console.error('Error reading inPersonCount:', error)
      throw error
    }
  }

  // static observeConferenceCheckInListPeople = (
  //   productId: string,
  //   listId: string,
  //   handleCheckInListPeople: any,
  // ) => {
  //   const peopleCollectionRef = collection(
  //     getFirestore(),
  //     'checkInLists',
  //     'conferences',
  //     productId,
  //     listId,
  //     'people',
  //   )

  //   const query = firestoreQuery(peopleCollectionRef)

  //   return onSnapshot(query, (querySnapshot) => {
  //     const people = querySnapshot.docs.reduce((acc, doc) => {
  //       acc[doc.id] = {
  //         ...doc.data(),
  //       }

  //       return acc
  //     }, {})

  //     handleCheckInListPeople(people)
  //   })
  // }
  static observeConferenceCheckInListTickets = (
    productId: string,
    listId: string,
    handleCheckInListTickets: any,
  ) => {
    const ticketsCollectionRef = collection(
      getFirestore(),
      'checkInLists',
      'conferences',
      productId,
      listId,
      'ticketCheckIns',
    )

    const query = firestoreQuery(ticketsCollectionRef)

    return onSnapshot(query, (querySnapshot) => {
      const tickets = querySnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = {
          ...doc.data(),
        }

        return acc
      }, {})

      handleCheckInListTickets(tickets)
    })
  }

  static saveConvinceTemplate = async (template) => {
    const connectionsRef = collection(getFirestore(), 'convinceTemplates')
    await addDoc(connectionsRef, template)
  }
}

export default ApiGateway
